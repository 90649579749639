const FastlyHeaders = {
  CountryCode: 'Fastly-Geo-Country',
  Latitude: 'Fastly-Geo-Latitude',
  Longitude: 'Fastly-Geo-Longitude',
  PostalCode: 'Fastly-Geo-Postal-Code',
  GMTOffset: 'Fastly-Geo-GMT-Offset',
  UTCOffset: 'Fastly-Geo-UTC-Offset',
  ProxyType: 'Fastly-Geo-Proxy-Type'
};
const AuthHeaders = {
  IsAnonymous: 'X-IHR-Is-Anonymous'
};
const AcceptEncoding = 'Accept-Encoding';
const UserTypeHeader = 'X-User-Type';
const NormalizedThemeHeader = 'X-Theme';
const MarketHeader = 'X-Market';

/** This header is supported by some browsers out of the box */
const SecCHPrefersColorScheme = 'Sec-Ch-prefers-color-scheme';
/** This header is a custom header implemented in userland to add support for browsers/environments which don't natively support `Sec-CH-Prefers-Color-Scheme */
const CHPrefersColorScheme = 'CH-prefers-color-scheme';

/** This is the cookie key to track the user-selected theme. It's possible that this cookie is nullish. */
const iHeartRadioTheme = 'iheartradio-theme';
const VaryHeaders = [AcceptEncoding, MarketHeader, NormalizedThemeHeader, SecCHPrefersColorScheme, UserTypeHeader];

export { AuthHeaders as A, CHPrefersColorScheme as C, FastlyHeaders as F, MarketHeader as M, NormalizedThemeHeader as N, SecCHPrefersColorScheme as S, UserTypeHeader as U, VaryHeaders as V, AcceptEncoding as a, iHeartRadioTheme as i };
